

.fisio {
    color: #007FF9;
    font-weight: 600;
}

.thera {
    color: #47B847;
    font-weight: 600;
}